import React from 'react'

export default function MiniSection() {
  return (
    <div className='mini--section'>
        <h2>We Offer Delivery Services, Car Hire, and General Contracts</h2> 
        <button>CONTACT US</button>
    </div>
  )
}
